import { ChatBubble } from "@mui/icons-material";
import { useRef, useState } from "react";

import { Box, Collapse, Fab, Tooltip } from "@mui/material";

import { useNavigate, useNavigation } from "react-router-dom";
import Messenger from "./../../assets/img/icon/messenger.svg";
import Whatsapp from "./../../assets/img/icon/whatsapp.svg";
import Zalo from "./../../assets/img/icon/zalo.svg";
import ZaloQr from "../../assets/img/zalo-qr.png";
import WhatsappQr from "../../assets/img/whatsapp-qr.png";
import ChatBox from "./ChatBox";
import MaintenanceModal from "./MaintenanceModal";
import { Image } from "antd";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiMenuFoldLine } from "react-icons/ri";
import { IoMdArrowDropright } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import "./styles.css";
const CHATBOT_MAINTENANCE = false;

export default function CustomChatWidget() {
  // Chatbot FAB
  const btnRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  // Selector

  const handleOpenChat = (e) => {
    // e.stopPropagation();
    setAnchorEl(btnRef.current);
  };

  const handleCloseChat = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const [open, setOpen] = useState(true);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };
  return (
    <Box
      ref={btnRef}
      p={2}
      display={"flex"}
      alignItems={"center"}
      gap={"10px"}
      minHeight={"95px"}
      zIndex={1000}
    >
      <Box display={{ xs: "block", sm: "none" }}>
        {open ? (
          <MdKeyboardArrowRight
            onClick={handleToggle}
            size={40}
            color="#0986CA"
            style={{ cursor: "pointer" }}
          />
        ) : (
          <Fab color="primary" onClick={handleToggle}>
            <RiMenuFoldLine size={24} />
          </Fab>
        )}
      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit orientation="horizontal">
        <Box
          display="flex"
          flexDirection={{ xs: "row", sm: "column" }}
          gap="10px"
        >
          <Tooltip title="Messenger" placement="left">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => window.open("https://m.me/aivision.vn", "_blank")}
              className="shake-icon"
            >
              <img
                src={Messenger}
                alt="Messenger"
                style={{ width: "58px", height: "58px" }}
              />
            </div>
          </Tooltip>
          <Tooltip title="Whatsapp" placement="left">
            <div style={{ cursor: "pointer" }} className="shake-icon">
              <Image
                src={Whatsapp}
                alt="Whatsapp"
                style={{ width: "56px", height: "56px" }}
                preview={{ src: WhatsappQr }}
              />
            </div>
          </Tooltip>
          <Tooltip title="Zalo" placement="left">
            <div
              style={{ cursor: "pointer" }}
              className="shake-icon"
              onClick={() =>
                window.open("https://zalo.me/0981419967", "_blank")
              }
            >
              <img
                src={Zalo}
                alt="Zalo"
                style={{ width: "54px", height: "54px" }}
              />
            </div>
          </Tooltip>
          <Tooltip title="Chatbot" placement="left">
            <Fab
              onClick={handleOpenChat}
              color="primary"
              // sx={{ padding: 2, position: "fixed", bottom: 50, right: 30 }}
            >
              <ChatBubble />
            </Fab>
          </Tooltip>
        </Box>
      </Collapse>

      {CHATBOT_MAINTENANCE ? (
        <MaintenanceModal
          anchorEl={anchorEl}
          handleOpenChat={handleOpenChat}
          handleCloseChat={handleCloseChat}
        />
      ) : (
        <ChatBox
          anchorEl={anchorEl}
          handleOpenChat={handleOpenChat}
          handleCloseChat={handleCloseChat}
        />
      )}
    </Box>
  );
}

import { Navigate } from "react-router-dom";
import { AdminLayout } from "./layout/AdminLayout";
import {
  BlogsManagePage,
  BlogCreatePage,
  BlogDetailPage,
  BlogEditPage,
} from "./pages";

export const adminRoutes = () => [
  {
    path: "/admin",
    name: "Admin",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/admin/blogs" />,
      },
      {
        path: "/admin/blogs",
        name: "Blogs",
        element: <BlogsManagePage />,
      },
      {
        path: "/admin/blogs/:slug",
        name: "Blogs Detail",
        element: <BlogDetailPage />,
      },
      {
        path: "/admin/blog-create",
        name: "Create Blog",
        element: <BlogCreatePage />,
      },
      {
        path: "/admin/blog-edit/:id",
        name: "Edit Blog",
        element: <BlogEditPage />,
      },
    ],
  },
];

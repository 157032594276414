import { Box, Grid, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate, useSearchParams } from "react-router-dom";
import banner from "assets/img/blog-banner.webp";
import Footer from "components/Footer";
import Menu from "components/Menu";
import BoxBlog from "../components/BoxBlog";
import { BlogListItem } from "../components/BlogListItem";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { blogsAction, languageSelector } from "../saga/blogSlice";

export default function BlogList() {
  const limit = 10;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalItem, setTotalItem] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const currentPage =
    Number(searchParams.get("page")) > 0 ? searchParams.get("page") : 1;

  const blogList = useSelector((state) => state.blogs.blogList);

  const blogCardList = (data) => {
    if (data == null || data.length === 0) {
      return null;
    }
    const blogList = data.map((item, index) => {
      return (
        <Grid
          key={index}
          item
          sm={6}
          md={4}
          sx={{ paddingLeft: { xs: "0!important", sm: "32px!important" } }}
        >
          <BlogListItem
            img={item.banner}
            content={language === "vi" ? item.content : item.en_content}
            title={language === "vi" ? item.title : item.en_title}
            slug={item.id}
            date={item.created_at}
          />
        </Grid>
      );
    });
    return blogList;
  };

  useEffect(() => {
    dispatch(blogsAction.getBlogs(currentPage, limit));
  }, [dispatch, currentPage]);

  const targetRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (targetRef.current) {
        const targetPosition =
          targetRef.current.getBoundingClientRect().top + window.scrollY;
        const scrollToPosition = targetPosition + 40;

        window.scrollTo({
          top: scrollToPosition,
          behavior: "smooth", // Cuộn mượt mà
        });
      }
    }, 500); // Thời gian chờ 500ms

    // Dọn dẹp timer khi component unmount
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {/* <Menu currentTab={3} /> */}
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${banner})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 600,
          marginBottom: "50px",
          "@media (max-width: 500px)": {
            height: 300,
            marginBottom: "10px",
          },
        }}
      >
        <Grid container height="inherit">
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"end"}
            alignItems={"center"}
            mb="60px"
          >
            <div ref={targetRef} />
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"10px 20px"}
              sx={{
                background: "#d61f23",
                color: "white",
                fontSize: "30px",
                textTransform: "uppercase",
                marginBottom: "15px",
              }}
            >
              <Typography
                onClick={() => navigate("/")}
                sx={{ cursor: "pointer" }}
              >
                {t("home_page_title")}
              </Typography>
              <ChevronRightIcon />
              <Typography>{t("blog_page_title")}</Typography>
            </Box>
            <Typography className="text-banner">
              {t("blog_page_title")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        padding={"0 100px"}
        display={"flex"}
        mt={"60px"}
        justifyContent="center"
        sx={{
          "@media (max-width: 500px)": {
            padding: "10px",
          },
        }}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={4}
          sx={{
            maxWidth: "1200px",
            "@media (max-width: 500px)": {
              padding: "10px",
              marginLeft: "unset",
              marginTop: "unset",
            },
          }}
        >
          {blogCardList(blogList)}
        </Grid>
      </Box>
      <Footer />
    </>
  );
}

import { axiosClient } from "utils/axiosClient";

export const blogsApi = {
  getAll() {
    return axiosClient.get("posts/all", {
      params: {
        limit: 20,
        next: 0,
      },
    });
  },
  getById(id) {
    return axiosClient.get(`posts/${id}`, {});
  },
};

export const categoriesApi = {
  getAll() {
    const url = "categories/get-list-category";
    return axiosClient.get(url, {
      params: {
        limit: 10,
        skip: 0,
      },
    });
  },
};

import DOMPurify from "isomorphic-dompurify";

const defaultOptions = {
  ALLOWED_TAGS: ["b", "i", "em", "strong", "a"],
  ALLOWED_ATTR: ["href"],
};

const sanitize = (dirty, options) => ({
  __html: DOMPurify.sanitize(dirty, { ...defaultOptions, ...options }),
});

export const SanitizeHTML = ({ html, options }) => (
  <div
    dangerouslySetInnerHTML={sanitize(html, options)}
    style={{
      whiteSpace: "normal",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      overflow: "hidden",
      minHeight: "48px",
    }}
  />
);

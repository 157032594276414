// import I18n from 'react-native-i18n';

// import en from './locales/en';
// import vi from './locales/vi';

// I18n.translations = {
//   en,
//   vi,
// };

// export default I18n;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en";
import vi from "./locales/vi";

const resources = {
  en: {
    translation: en,
  },
  vi: {
    translation: vi,
  },
};

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources,
  lng: "vi",
  // interpolation: {
    //   escapeValue: false,
  // },
  returnNull: false,
});

export default i18n;

/**
 *
 * @param {string} value //vi or en
 */
export function setLocalLanguage(value) {
  window.localStorage.setItem("language", value);
}

export function getLocalLanguage() {
  const lang = window.localStorage.getItem("language");
  return lang;
}

import Footer from "../../components/Footer";
import Menu from "../../components/Menu/index";
import iconAI from "../../assets/img/icon-ai.svg";
import iconChatbot from "../../assets/img/icon-chatbot.svg";
import iconData from "../../assets/img/icon-data.svg";
import iconHR from "../../assets/img/icon-hr.svg";

import bgPatternCircle from "../../assets/img/bg-pattern-circle.webp";
import banner from "../../assets/img/services-banner.webp";

import { Box, Button, Grid, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import ServiceBox from "../../components/ServiceBox";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";

export default function ServicesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const serviceList = [
    {
      title: t("ai_service_title"),
      description: t("ai_service_description"),
      icon: iconAI,
      link: "/services/detail/1",
    },
    {
      title: t("chatbot_service_title"),
      description: t("chatbot_service_description"),
      icon: iconChatbot,
      link: "/services/detail/2",
    },
    {
      title: t("data_analytics_service_title"),
      description: t("data_analytics_service_description"),
      icon: iconData,
      link: "/services/detail/3",
    },
    {
      title: t("smart_hr_service_title"),
      description: t("smart_hr_service_description"),
      icon: iconHR,
      link: "/services/detail/4",
    },
  ];
  const targetRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (targetRef.current) {
        targetRef.current.scrollIntoView({
          behavior: "smooth", // Cuộn mượt mà
        });
      }
    }, 500); // Thời gian chờ 500ms

    // Dọn dẹp timer khi component unmount
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {/* <Menu currentTab={2} /> */}
      {/* <!-- Header Area End -->
    <!-- Banner Area Start --> */}
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${banner})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 600,
          marginBottom: "50px",
          "@media (max-width: 500px)": {
            height: 300,
            marginBottom: "10px",
          },
        }}
      >
        <Grid container height="inherit">
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"end"}
            alignItems={"center"}
            mb="60px"
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"10px 20px"}
              sx={{
                background: "#d61f23",
                color: "white",
                fontSize: "30px",
                textTransform: "uppercase",
                marginBottom: "15px",
              }}
            >
              <Typography
                onClick={() => navigate("/")}
                sx={{ cursor: "pointer" }}
              >
                {t("home_page_title")}
              </Typography>
              <ChevronRightIcon />
              <Typography>{t("services_page_title")}</Typography>
            </Box>
            <div ref={targetRef} />
            <Typography className="text-banner">
              {t("services_page_title")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* <!-- Banner Area End -->
    <!-- Experience Area Start --> */}
      <Box display={"flex"} justifyContent={"center"}>
        <Grid
          container
          spacing={2}
          mb={10}
          justifyContent={"space-around"}
          display={"flex"}
          sx={{
            maxWidth: "1400px",
            "@media (max-width: 500px)": {
              marginBottom: "30px",
            },
          }}
        >
          <Grid
            item
            container
            md={7}
            display={"flex"}
            justifyContent={"center"}
            // sx={{ maxWidth: "700px !important" }}
          >
            <Grid
              item
              container
              md={12}
              display={"flex"}
              justifyContent={"center"}
              sx={{ maxWidth: "700px !important" }}
            >
              {serviceList.map((item, index) => {
                return (
                  <ServiceBox
                    key={index}
                    title={item.title}
                    description={item.description}
                    icon={item.icon}
                    link={item.link}
                  />
                );
              })}
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={5}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            sx={{
              position: "relative",
              "@media (max-width: 500px)": {
                margin: "10px",
                alignItems: "center",
              },
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${bgPatternCircle})`,
                backgroundPosition: "180% 50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "500px",
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: "-1",
                filter: "blur(0.8px)",
                "@media (max-width: 500px)": {
                  backgroundPosition: "100% 120%",
                  backgroundSize: "200px",
                },
              }}
            />
            <Typography sx={{ fontSize: "30px", marginBottom: "20px" }}>
              {t("discover_services_title")}
            </Typography>
            {/* <Typography sx={{ fontSize: "30px", marginBottom: "50px" }}>
            For People Choosing
          </Typography> */}
            <Typography
              sx={{
                fontSize: "16px",
                marginBottom: "50px",
                "@media (max-width: 500px)": {
                  marginBottom: "15px",
                },
              }}
            >
              {t("discover_services_description")}
            </Typography>
            <Button
              variant="contained"
              className="btn-red"
              sx={{ width: "100px" }}
              onClick={() => navigate("/services/detail/1")}
            >
              {t("detail_btn")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </>
  );
}

// import logo from "../../assets/img/logo-1.png";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Drawer,
  Grid,
  Typography
} from "@mui/material";
import { blogsAction } from "pages/Blogs/saga/blogSlice";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrLanguage } from "react-icons/gr";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo_aivision.webp";
import { language } from "../../constants/common";
import { getLocalLanguage, setLocalLanguage } from "../../utils/storage";
import "./styles.css";
export default function Menu(props) {
  const { t, i18n } = useTranslation();
  const { currentTab } = props;
  const navigate = useNavigate();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const menuList = [
    {
      name: t("home_page_title"),
      href: "/",
    },
    {
      name: t("about_page_title"),
      href: "/about-us",
    },
    {
      name: t("services_page_title"),
      href: "/services",
    },
    {
      name: t("blog_page_title"),
      href: "/blogs",
    },

    {
      name: t("contact_page_title"),
      href: "/contact",
    },
  ];
  const domain = window.location.hostname;
  console.log(domain);
  const dispatch = useDispatch();
  const [lang, setLang] = useState(
    domain === "aivision.vn" ? language[0].value : language[1].value
  );

  useEffect(() => {
    const langLocal = getLocalLanguage();
    if (langLocal) {
      // const langObj = language.filter((item) => item.value === lang);
      setLang(langLocal);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
    dispatch(blogsAction.setLanguage(lang));
  }, [lang]);

  function handleChangeLanguage(value) {
    setLocalLanguage(value);
    setLang(value);
  }
  console.log(lang);

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: "white",
        boxShadow: 1,
        zIndex: 999,
      }}
    >
      <Grid container sx={{ justifyContent: "center", position: "relative" }}>
        <Grid
          item
          xs={2}
          md={4}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            position: "relative",
            height: "100px",
            "@media (max-width: 500px)": {
              height: "80px",
            },
          }}
        >
          <img
            src={logo}
            alt="logo"
            width={"150px"}
            className="logo-img"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              margin: "auto",
            }}
          />
        </Grid>
        <Grid
          className="menu-desktop"
          item
          container
          xs={10}
          md={8}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {menuList.map((item, index) => {
            return (
              <Box
                className={`menu-link ${
                  currentTab === index ? "active-link" : ""
                }`}
                key={index}
                onClick={() => navigate(`${item.href}`)}
              >
                <Typography>{item.name}</Typography>
              </Box>
            );
          })}

          <Box
            className="menu-link nohover"
            display={"flex"}
            alignItems={"center"}
            gap={"6px"}
            component={"div"}
            onClick={() =>
              handleChangeLanguage(
                language.find((item) => item.value !== lang).value
              )
            }
          >
            <GrLanguage color="#000000de" size={22} />
            <Typography>
              {language.find((item) => item.value === lang)?.label}
            </Typography>
          </Box>
        </Grid>
        <Grid className="menu-mobile">
          <MenuIcon onClick={() => setIsOpenMenu(true)} />
          <Box>
            <Drawer
              anchor={"right"}
              open={isOpenMenu}
              onClose={() => setIsOpenMenu(false)}
            >
              {menuList.map((item, index) => {
                return (
                  <Box
                    className={`menu-link ${
                      currentTab === index ? "active-link" : ""
                    }`}
                    key={index}
                    onClick={() => navigate(`${item.href}`)}
                    sx={{
                      width: "200px",
                      textAlign: "center",
                      margin: "20px 0",
                      "&:before": {
                        right: 0,
                        margin: "auto",
                      },
                    }}
                  >
                    <Typography>{item.name}</Typography>
                  </Box>
                );
              })}
              <Box
                className="menu-link nohover"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"6px"}
                margin={0}
                component={"div"}
                onClick={() =>
                  handleChangeLanguage(
                    language.find((item) => item.value !== lang).value
                  )
                }
              >
                <GrLanguage color="#000000de" size={22} />
                <Typography>
                  {language.find((item) => item.value === lang)?.label}
                </Typography>
              </Box>
            </Drawer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

import { Box } from "@mui/material";

export default function HighlightContent({ text }) {
  return (
    <Box
      sx={{
        borderLeft: "2px solid red",
        padding: "10px 20px",
        boxShadow: "5px 5px 3px #c3c0c0",
        background: "white",
      }}
    >
      {text}
    </Box>
  );
}

import {
  Box,
  FormHelperText,
  Input,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { ImageUpload } from "components/ImageUpload";
import { TextEditor } from "components/TextEditor";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import {
  convertToImageListType,
  convertToReduceImageList,
} from "utils/imageUpload";

export const BlogForm = ({
  bannerFile,
  en_content,
  content,
  noEdit,
  control,
  errors,
  setValue,
  setFocus,
}) => {
  const editorRef = useRef();
  const editorEnRef = useRef();
  const imageRef = useRef();
  const [formImageList, setFormImageList] = useState([]);
  useEffect(() => {
    if (bannerFile) {
      setFormImageList([bannerFile]);
    }
  }, [bannerFile]);
  const handleTextClick = () => {
    if (editorRef.current) {
      //
    }
  };

  const onTextEditorChange = (data) => {
    // console.log("data: ", data);
    console.log(data);
    setValue("content", data);
  };

  const onTextEditorENChange = (data) => {
    // console.log("data: ", data);
    console.log(data);
    setValue("en_content", data);
  };

  const onChangeImage = (imageList, addUpdateIndex) => {
    console.log(imageList);
    setValue("image", convertToReduceImageList(imageList));
    setFormImageList((prev) => convertToReduceImageList(imageList));
  };

  useEffect(() => {
    const firstError = Object.keys(errors)[0];
    if (firstError) {
      if (firstError === "content") {
        const element = document.getElementsByClassName("ck ck-editor")[0];
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          editorRef.current.focus();
        }
      } else if (firstError === "image") {
        imageRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      } else {
        const element = document.getElementById(firstError);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          setFocus(firstError);
        }
      }
    }
  }, [errors, setFocus]);

  return (
    <form id="event-form">
      <Box display="flex" flexDirection="column" width="100%">
        <Box className="p-10 grid grid-cols-1 gap-9" borderRadius="3px">
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <Box display="flex" flexDirection="column" gap={2}>
                <div className="font-bold text-primary leading-tight">
                  Tiêu đề bài viết (VN) <span className="text-red-500">*</span>
                </div>
                <OutlinedInput
                  id="title"
                  {...field}
                  fullWidth
                  error={errors.title ? true : false}
                />
                <FormHelperText error>{errors.title?.message}</FormHelperText>
              </Box>
            )}
          />
          <Controller
            control={control}
            name="en_title"
            render={({ field }) => (
              <Box display="flex" flexDirection="column" gap={2}>
                <div className="font-bold text-primary leading-tight">
                  Tiêu đề bài viết (EN) <span className="text-red-500">*</span>
                </div>
                <OutlinedInput
                  id="en_title"
                  {...field}
                  fullWidth
                  error={errors.en_title ? true : false}
                />
                <FormHelperText error>
                  {errors.en_title?.message}
                </FormHelperText>
              </Box>
            )}
          />

          <Controller
            control={control}
            name="image"
            render={({ field }) => (
              <Box display="flex" flexDirection="column" gap={2}>
                <div className="font-bold text-primary leading-tight ">
                  Ảnh tiêu đề <span className="text-red-500">* </span>
                  <span className="text-xs text-gray">
                    (Ảnh nên có tỉ lệ là 16/9)
                  </span>
                </div>
                <Box
                  ref={imageRef}
                  className="bg-white"
                  height="300px"
                  borderRadius="10px"
                >
                  <ImageUpload
                    noEdit={false}
                    onChange={onChangeImage}
                    isMultiple={true}
                    imageList={convertToImageListType(formImageList)}
                  />
                </Box>
                <FormHelperText error>{errors.image?.message}</FormHelperText>
              </Box>
            )}
          />

          <Controller
            control={control}
            name="content"
            render={({ field }) => (
              <Box display="flex" flexDirection="column" gap={2}>
                <div className="font-bold text-primary leading-tight my-4">
                  Nội dung bài viết (VN) <span className="text-red-500">*</span>
                </div>
                <Box
                  borderRadius="5px"
                  height="600px"
                  bgcolor="white"
                  onClick={handleTextClick}
                  sx={{
                    overflow: "auto",
                    ".ck-editor__editable": {
                      minHeight: "722px",
                      // outline: "none",
                    },
                    ".ck-editor__editable_inline p": {
                      // margin: "0px",
                      // minHeight: "722px",
                      // outline: "none",
                    },
                  }}
                >
                  <TextEditor
                    {...field}
                    propRef={editorRef}
                    noEdit={noEdit}
                    initialData={content}
                    onChange={onTextEditorChange}
                    uploadImageRequestHeader={{
                      // [hAuthName]: hAuthValue,
                      "Content-Type": "application/x-www-form-urlencoded",
                    }}
                    uploadImageRequestUrl={process.env.REACT_APP_URL + "/image"}
                    post_id={null}
                  />
                </Box>
                <FormHelperText error>{errors.content?.message}</FormHelperText>
              </Box>
            )}
          />
          <Controller
            control={control}
            name="en_content"
            render={({ field }) => (
              <Box display="flex" flexDirection="column" gap={2}>
                <div className="font-bold text-primary leading-tight my-4">
                  Nội dung bài viết (EN) <span className="text-red-500">*</span>
                </div>
                <Box
                  borderRadius="5px"
                  height="600px"
                  bgcolor="white"
                  onClick={handleTextClick}
                  sx={{
                    overflow: "auto",
                    ".ck-editor__editable": {
                      minHeight: "722px",
                      // outline: "none",
                    },
                    ".ck-editor__editable_inline p": {
                      // margin: "0px",
                      // minHeight: "722px",
                      // outline: "none",
                    },
                  }}
                >
                  <TextEditor
                    {...field}
                    propRef={editorEnRef}
                    noEdit={noEdit}
                    initialData={en_content}
                    onChange={onTextEditorENChange}
                    uploadImageRequestHeader={{
                      // [hAuthName]: hAuthValue,
                      "Content-Type": "application/x-www-form-urlencoded",
                    }}
                    uploadImageRequestUrl={process.env.REACT_APP_URL + "/image"}
                    post_id={null}
                  />
                </Box>
                <FormHelperText error>
                  {errors.en_content?.message}
                </FormHelperText>
              </Box>
            )}
          />
        </Box>
      </Box>
    </form>
  );
};

import { takeEvery, call, put } from "redux-saga/effects";
import { authApi } from "../authApi";
import { authAction } from "./authSlice";

export function* handleLogin(action) {
  try {
    const response = yield call(authApi.getToken, action.payload);
    if (response && response.status === 200) {
      yield put(authAction.loginSuccess(response.data));
    } else {
      console.log("Failed to login", response.message);
      yield put(authAction.loginFailed(response.message));
    }
  } catch (error) {
    console.log("error");
    console.log(error);
    yield put(authAction.loginFailed(error.message));
  }
}

export default function* authSaga() {
  yield takeEvery(authAction.login, handleLogin);
}

import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function ImageHandle({ src, defaultSrc, ...rest }) {

  const onImageError = (e) => {
    e.target.src = defaultSrc;
  };



  return <img src={src} alt="" onError={onImageError} {...rest} />;
}

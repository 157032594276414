import { Box, Button, Typography } from "@mui/material";
import blogBanner from "assets/img/blog_1.jpg";
import { SanitizeHTML } from "components/SanitizeHTML";
import { getFirstParagraph } from "utils/getDescription";
import { API_URL_ROOT, IMAGE_URL_ROOT } from "utils/constant";
import { ImageHandle } from "components/ImageHandler";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const BlogListItem = ({ img, content, title, slug, date }) => {
  const description = getFirstParagraph(content);
  console.log(description);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      borderBottom={1}
      borderColor="divider"
      className="mb-10"
    >
      <Box
        bgcolor="white"
        // width="800px"
        // height="400px"
        position="relative"
        borderRadius="10px"
        // sx={{
        //   "@media (max-width: 768px)": {
        //     height: "200px",
        //   },
        // }}
        className="w-full aspect-video"
      >
        <ImageHandle
          src={IMAGE_URL_ROOT + img?.replace("static/images/", "")}
          defaultSrc={blogBanner}
          style={{ width: "100%", height: "200px" }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        className="gap-5 py-3 mb-7"
      >
        <Link
          to={`/blogs/${slug}`}
          className="text-primary text-2xl font-medium no-underline min-h-[64px]"
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
          }}
        >
          {title}
        </Link>

        <Typography className="text-neutral-400 line-clamp-3 whitespace-pre-line">
          {/* {props.content} */}
          <SanitizeHTML html={description} options={{}} />
        </Typography>

        <div className="w-full flex justify-end md:justify-end">
          <Button
            className="p-3 "
            size="large"
            onClick={() => {
              navigate(`/blogs/${slug}`);
            }}
            style={{
              borderRadius: "10px",
            }}
          >
            <div className="text-sm font-bold">{t("read_more")}</div>
          </Button>
        </div>
      </Box>
    </Box>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 500px) {
  .extendImgLogo {
    display: none;
  }

  .tag-title {
    font-size: 0.8rem !important;
    padding: 5px 20px 5px 20px;
  }

  .contact-map {
    width: 100% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/responsive.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;;EAEA;IACE,4BAA4B;IAC5B,0BAA0B;EAC5B;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":["@media (max-width: 500px) {\n  .extendImgLogo {\n    display: none;\n  }\n\n  .tag-title {\n    font-size: 0.8rem !important;\n    padding: 5px 20px 5px 20px;\n  }\n\n  .contact-map {\n    width: 100% !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

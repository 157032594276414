import { Box, Button, keyframes } from "@mui/material";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { authAction } from "pages/Auth/saga/authSlice";
import logo from "assets/img/logo-aivision.webp";
const descendAnimation = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export function AdminTopBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="flex h-24 relative w-full">
      <Box
        className={classNames(
          "flex border-2 pl-2.5 h-24 items-center gap-7 justify-center z-[500] bg-slate-50 w-full absolute"
        )}
        sx={{
          // position: headerOnScreen ? "fixed" : "fixed",
          boxShadow: "0 0 5px 0px #000000",
          transition: `${descendAnimation} .2s ease-out forwards`,
          top: 0,
          left: 0,
        }}
      >
        <div className="flex w-full mx-4 items-center">
          <Box
            className="h-16 aspect-[2/1] md:h-14 relative"
            sx={{
              cursor: "pointer",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                maxHeight: "100%",
                width: "auto", // Đảm bảo chiều rộng tự động để giữ tỷ lệ khung hình
                objectFit: "contain",
                position: "absolute", // Nếu cần thiết
                left: 0,
                top: 0, // Nếu cần thiết
              }}
            />
          </Box>
          <div className="flex-1" />
          {/* <IconButton>
            <Menu />
          </IconButton> */}
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(authAction.logout());
              navigate("/login");
            }}
          >
            Đăng xuất
          </Button>
        </div>
      </Box>
    </div>
  );
}

import { axiosClient } from "utils/axiosClient";

export const authApi = {
  getToken(data) {
    const url = "/user/login";
    const params = new URLSearchParams(data).toString();
    return axiosClient.post(url, params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  autoLogin() {
    const url = "/user/login";
    const data = {
      username: "a@a.a",
      password: "1",
    };
    return axiosClient.post(url, data);
  },
};

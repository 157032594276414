import { ArrowForward, Close, Send } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  OutlinedInput,
  Typography,
  useTheme
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import iconChatBot from "assets/img/icon-chatbot.svg";
import { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from "uuid";
import { MsgButton } from "../MsgButton";
import { MyMessage } from "../MyMessage";
import { OtherMessage } from "../OtherMessage";
import {
  chatbotAction,
  selectHistoryMsg,
  selectReceiveMessage,
  selectSessionId,
} from "../saga/chatbotSlice";
import { TypingDot } from "../Typingdot";

const ChatBox = ({ anchorEl, handleOpenChat, handleCloseChat }) => {
  const dispatch = useDispatch();
  const sideMenuOpen = Boolean(anchorEl);
  const [cookies, setCookie] = useCookies(['sessionchatid']);
  // Msg state
  const [errorMessage, setErrorMessage] = useState("");
  const [msgList, setMsgList] = useState([]);
  const [myMessage, setMyMessage] = useState("");
  const [historyMsg, setHistoryMsg] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [typeMeeting, setTypeMeeting] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [recomMessage, setRecomMessage] = useState([]);
  const [isBlock, setIsBlock] = useState(false);
  const sessionId = useSelector(selectSessionId);
  const receivedMessage = useSelector(selectReceiveMessage);
  const historyMsgReceived = useSelector(selectHistoryMsg);
  const isLoading = useSelector((state) => state.chatbot.isLoading);
  const errorCallApiMsg = useSelector((state) => state.chatbot.errormsg);
  const isStartStreaming = useSelector((state) => state.chatbot.isStartStreaming);
  const isFetching = useSelector((state) => state.chatbot.isFetching);
  const welcomeMessage = useSelector((state) => state.chatbot.welcomeMessage);
  const theme = useTheme();
  const matches = useMediaQuery('(max-width:500px)');

  useEffect(() => {
    if (welcomeMessage && welcomeMessage.messages 
      && welcomeMessage.messages[0].content !== "") {
      setMsgList((preMsg) => {
        const newWelcomeMessage = {
          mine: false,
          content: welcomeMessage.messages[0].content,
          id: welcomeMessage.messages[0].id,
        };
        return [newWelcomeMessage, ...preMsg];
      });
      if (welcomeMessage.recom.length > 0) {
        setRecomMessage(welcomeMessage.recom);
      }
      else {
        setIsTimeout(true);
      }
    }
  }, [welcomeMessage]); // Run the effect only when welcomeMessage changes

  const sendRecomMessage = (recom) => {
    getReplyMessage(recom);
  };

  const handleScroll = (e) => {
    let element = e.target;
    // column-reverse
    if (0.98 * (element.scrollTop + element.scrollHeight) 
      <= element.clientHeight && isFetching === false && hasMore === true) {
      console.log("Fetching history messages");
      dispatch(chatbotAction.fetchHistoryMessages({
        id: cookies.sessionchatid,
        limit: 10,
        offset: offset})
      );
      setOffset(offset + 10);
    }
 }

  const getReplyMessage = (user_message, combined_id=false) => {
    const newMsg = {
      mine: true,
      content: user_message,
      id: uuidv4(),
    };
    setMyMessage("");
    setConfirm(false);
    setTypeMeeting(false);
    setErrorMessage("");
    setRecomMessage([]);
    setMsgList((preMsg) => [newMsg, ...preMsg]);
    if (sessionId.id === null) {
      dispatch(chatbotAction.sendFirstMsg({message: user_message, combined_id: combined_id}));
    } else {
      dispatch(
        chatbotAction.getChatbotMessage({
          sessionId: sessionId.id,
          message: user_message,
          combined_id: combined_id,
        })
      );
    }
  };

  const handleSendMessage = () => {
    if (myMessage === "") return;
    getReplyMessage(myMessage);
  };

  const notify = () => toast.error(errorCallApiMsg);

  const handleMessageChange = (e) => {
    e.preventDefault();
    setMyMessage(e.currentTarget.value);
  };

  const handleStreamMessage = (message) => {
    if (message.status === "ERROR") {
      setErrorMessage("Có lỗi xảy ra, vui lòng thử lại sau");
      return;
    }
    if (message.messages.length > 0) {
        let tempMsgList = [...msgList];
        message.messages.forEach((msg) => {
          const newStreamingMessage = {
            mine: false,
            content: msg.content,
            id: msg.id,
          };
          const existingMessageIndex = msgList.findIndex(
            (msg) => msg.id === newStreamingMessage.id
          );
          if (existingMessageIndex > -1) {
            tempMsgList[existingMessageIndex] = newStreamingMessage;
          } else {
            tempMsgList = [newStreamingMessage, ...tempMsgList];
          }
        });
        setMsgList(tempMsgList);
    }
    if (message.recom) {
      setRecomMessage(message.recom);
    }
    if (message.missing_field == "type") {
      setTypeMeeting(true);
      setIsBlock(true);
    } else if (message.missing_field == "isConfirmed") {
      setConfirm(true);
    }
  };
  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleTypeMeeting = (msg) => {
    setIsBlock(false);
    getReplyMessage(msg, true);
  };

  const handleConfirmBooking = (msg) => {
    getReplyMessage(msg, true);
  };

  useEffect(() => {
    // if (!matches) handleOpenChat();
    dispatch(chatbotAction.fetchWelcomeMessage({id: cookies.sessionchatid || sessionId.id}));
    if (cookies.sessionchatid) {
      dispatch(chatbotAction.fetchHistoryMessages({id: cookies.sessionchatid, limit: 10, offset: offset}));
      setOffset(offset + 10);
      dispatch(chatbotAction.getSessionIdSuccess({ id: cookies.sessionchatid }));
    }
  }, []);

  useEffect(() => {
    if (sessionId.id) {
      setCookie('sessionchatid', sessionId.id, {
         path: '/',
         secure: false,
         //sameSite: "none",
         //httpOnly: true,
         expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000), // 1 year
      });
    }
  }, [sessionId]);

  useEffect(() => {
    if (historyMsg.length > 0 && historyMsgReceived.length == 0) {
      setHasMore(false);
    }
    // Add old messages to historyMsg
    setHistoryMsg((preMsg) => {
      const oldMessages = historyMsgReceived.map((e) => {
        const isMine = e.role === "user" ? true : false;
        return {
          mine: isMine,
          content: e.content,
          id: e.id
        };
    })
    return [...preMsg, ...oldMessages];
    });
  }, [historyMsgReceived]);

  useEffect(() => {
    if (errorCallApiMsg) {
      // notify();
      dispatch(chatbotAction.clearErrorMessage());
    }
  }, [errorCallApiMsg]);

  useEffect(() => {
    if (receivedMessage) {
      handleStreamMessage(receivedMessage);
    }
  }, [receivedMessage]);

  return (
    <Menu
      sx={{
        marginRight: 1,
        "& .MuiMenu-paper": {
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          borderRadius: "10px",
        },
        "& .MuiMenu-list": {
          paddingTop: 0,
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      anchorEl={anchorEl}
      open={sideMenuOpen}
      onClose={handleCloseChat}
      disableScrollLock={true}
    >
      <Box
        sx={{
          height: 600,
          width: 450,
          display: "flex",
          flexDirection: "column",
          flex: 1,
          [theme.breakpoints.down('sm')]: {
            height: 550, // Adjust styles for small-sized screens and below
            width: 400
          },
          [theme.breakpoints.down(440)]: {
            width: 360
          }
        }}
      >
        <Box
          bgcolor="white"
          boxShadow="0 0 1px rgba(0, 0, 0, 0.8)"
          padding={1}
          display="flex"
          alignItems="center"
        >
          <Avatar src={iconChatBot} />
          <Typography px={1}>AIV Chatbot</Typography>
          <Box marginLeft="auto">
          <IconButton 
            aria-label="close"
            sx={{
              borderRadius: "20%",
            }}
            onClick={handleCloseChat}
          >
            <Close />
          </IconButton>
          </Box>
        </Box>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={1}
          >
            {isFetching && <CircularProgress size="1.5rem" sx={{color: "#e0e0e0"}} />}
          </Box>
        <Box
          display="flex"
          maxHeight={500}
          flex={1}
          padding={1}
          overflow="auto"
          onScroll={handleScroll}
          flexDirection="column-reverse"
          sx={{
            '&::-webkit-scrollbar-thumb': {
              height: '5px',
              borderRadius: '10px'
            },
            '&::-webkit-scrollbar': {
              width: '0.5em',
            },
            '&:hover::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.08)'
            }
          }}
        > 
          
          {errorMessage && <Alert  variant="filled" severity="error">{errorMessage}</Alert>}
          {isStartStreaming === false && (
            <Box
              bgcolor="none"
              padding={1}
              display="flex"
              alignItems="center"
            >
              <Avatar src={iconChatBot} />
              <TypingDot />
            </Box>
          )}
          {confirm && (MsgButton("Xác nhận lịch hẹn", "Huỷ lịch", handleConfirmBooking))}
          {typeMeeting && (MsgButton("Trực tiếp", "Trực tuyến", handleTypeMeeting))}
          {recomMessage.length > 0 && (
            <Box
              padding={1}
              display="flex"
              height="min-content"
              marginLeft={1}
              flexDirection="column"
            >
              {recomMessage.map((recom, idx) => (
                <Button
                  style={{ textTransform: "none"}}
                  sx={{ marginTop: 1, borderRadius: "16px"}}
                  onClick={() => sendRecomMessage(recom)}
                  variant="outlined"
                  key={idx}
                >
                  <Typography sx={{textAlign: 'left', fontSize: "0.85rem"}}>
                    {recom}
                  </Typography>
                  <ArrowForward sx={{ marginLeft: "auto" }} />
                </Button>
              ))}
            </Box>
          )}
          {msgList.map((e, idx) => {
            if (e.mine) {
              return MyMessage(e.content, idx);
            } else {
              return OtherMessage(e.content, idx);
            }
          })}
          {isTimeout && (<Divider variant="middle" sx={{marginTop: 1, marginBottom: 1}} />)}
          {historyMsg.map((e, idx) => {
            if (e.mine) {
              return MyMessage(e.content, idx);
            } else {
              return OtherMessage(e.content, idx);
            }
          })}
        </Box>
        <OutlinedInput
          sx={{
            backgroundColor: "white",
            borderRadius: 10,
            mx: 3,
            my: 2,
            "& .MuiInputBase-input": {
              border: "none",
            },
          }}
          size="small"
          disabled={!!isLoading || isBlock}
          placeholder="Nhập câu hỏi của bạn..."
          multiline
          maxRows={16}
          value={myMessage}
          inputProps={{ maxLength: 300 }}
          onChange={handleMessageChange}
          onKeyDown={handleKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                type="submit"
                sx={{ 
                  marginRight: -1.2,
                  height: "2.2rem",
                  width: "2.2rem",
                  color: myMessage ? "white" : "#bdbdbd",
                  backgroundColor: myMessage ? "#0a68ff" : "#eeeeee", 
                  "&:hover": {
                    backgroundColor: myMessage ? "#0a68ff" : "#eeeeee",
                  },
                }}
                onClick={handleSendMessage}
                edge="end"
              >
                <Send />
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>
    </Menu>
  );
};

export default ChatBox;
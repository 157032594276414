
import createCache from "@emotion/cache";

import { CacheProvider } from "@emotion/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useState } from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0986CA",
      light: "#67C4F6",
      dark: "#0069A3",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "inherit",
    // htmlFontSize: 10,
    // fontSize: 'inherit',
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          border: "1px solid #0986CA",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          cursor: "unset",
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          cursor: "unset",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          border: "1px solid #0986CA",
        },
        head: {
          backgroundColor: "#ffffff",
          border: "1px solid #0986CA",
        },
      },
    },
  },
});

export default function ThemeRegistry(props) {
  const { options, children } = props;

  const [{ cache, flush }] = useState(() => {
    const cache = createCache(options);
    cache.compat = true;
    const prevInsert = cache.insert;
    let inserted = [];
    cache.insert = (...args) => {
      const serialized = args[1];
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name);
      }
      return prevInsert(...args);
    };
    const flush = () => {
      const prevInserted = inserted;
      inserted = [];
      return prevInserted;
    };
    return { cache, flush };
  });

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
}

import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
// import Editor from "ckeditor5-custom-build/build/ckeditor";
// import DefaultEditor from "@ckeditor/ckeditor5-build-classic";
// import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useEffect, useState } from "react";

// mui
import { Backdrop, CircularProgress } from "@mui/material";
import { adminApi } from "pages/Admin/adminsApi";
import { API_URL_ROOT } from "utils/constant";
import { CustomUploader } from "./CustomUpload";

export const TextEditor = ({
  propRef,
  initialData,
  onChange,
  noEdit = false,
  readonly,
  onReady,
  onError,
  uploadImageRequestHeader,
  uploadImageRequestUrl,
  post_id,
}) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // editorRef.current = {
    //   CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
    //   Editor: require("ckeditor5-custom-build/build/ckeditor"),
    //   // Editor: require("@/classic-ckeditor/build/ckeditor")
    // };
    setIsReady(true);
  }, []);

  //   if (uploadImageRequestUrl === "")
  //     return <p>Please provide image request url!</p>;

  return isReady ? (
    <CKEditor
      id="blog_editor"
      disabled={noEdit}
      editor={Editor}
      data={initialData}
      config={{
        extraPlugins: [],
        removePlugins: ["Title", uploadImageRequestUrl === "" ? "Image" : ""],
      }}
      onReady={(editor) => {
        if (readonly) editor.ui.view.toolbar.element.style.display = "none";
        if (typeof onReady !== "undefined") onReady();
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return new CustomUploader(loader, uploadImageRequestUrl, post_id);
        };
        editor.model.document.on("change:data", () => {
          const changes = editor.model.document.differ.getChanges();
          changes.forEach((change) => {
            if (change.type === "remove") {
              if (change.name === "imageBlock") {
                console.log("change", change);
                const deletedList = change.attributes;
                deletedList.forEach((value, key) => {
                  if (key === "src") {
                    const image_name = value.replace(
                      API_URL_ROOT + "/StaticFolder/posts/",
                      ""
                    );
                    adminApi.deleteBlogImage(image_name).then((res) => {
                      console.log("Image removed", res);
                    });
                  }
                });
              }
            }
          });
        });
        propRef.current = editor;
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        // console.log({ data });
        if (onChange) {
          onChange(data);
        }
      }}
      onError={(error, details) => {
        if (typeof onError !== "undefined") onError();
        console.log({ error, details });
      }}
    />
  ) : (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={!isReady}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

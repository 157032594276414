import { yupResolver } from "@hookform/resolvers/yup";
import { array, mixed, object, string } from "yup";

const requiredMsg = "Trường thông tin này là bắt buộc";

const imageSchema = object().shape({
  url: string().required(requiredMsg),
  file: mixed().nullable().default(null),
});

const imageFieldSchema = array()
  .of(imageSchema)
  .required(requiredMsg)
  .min(1, requiredMsg);

export const resolver = yupResolver(
  object().shape({
    title: string().required(requiredMsg),
    content: string().required(requiredMsg),
    image: imageFieldSchema,
  })
);

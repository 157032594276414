// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typing {
    width: 3em;
    height: 1em;
    position: relative;
    padding: 10px;
    margin-left: 5px;
    background: #e6e6e6;
    border-radius: 20px;
  }
  
  .typing__dot {
    float: left;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: #8b8b8d;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
  }
  
  .typing__dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .typing__dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typing__dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes loadingFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/CustomChatWidget/Typingdot/styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,UAAU;IACV,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;IACV,kCAAkC;EACpC;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,YAAY;IACd;IACA;MACE,UAAU;IACZ;EACF","sourcesContent":[".typing {\n    width: 3em;\n    height: 1em;\n    position: relative;\n    padding: 10px;\n    margin-left: 5px;\n    background: #e6e6e6;\n    border-radius: 20px;\n  }\n  \n  .typing__dot {\n    float: left;\n    width: 8px;\n    height: 8px;\n    margin: 0 4px;\n    background: #8b8b8d;\n    border-radius: 50%;\n    opacity: 0;\n    animation: loadingFade 1s infinite;\n  }\n  \n  .typing__dot:nth-child(1) {\n    animation-delay: 0s;\n  }\n  \n  .typing__dot:nth-child(2) {\n    animation-delay: 0.2s;\n  }\n  \n  .typing__dot:nth-child(3) {\n    animation-delay: 0.4s;\n  }\n  \n  @keyframes loadingFade {\n    0% {\n      opacity: 0;\n    }\n    50% {\n      opacity: 0.8;\n    }\n    100% {\n      opacity: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

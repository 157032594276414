import Cookies from "js-cookie";

export function setAuthCookie(auth) {
  const { access_token, refresh_token, token_type } = auth;
  Cookies.set("access_token", access_token);
  Cookies.set("refresh_token", refresh_token);
  Cookies.set("token_type", token_type);
}

export function getAuthCookie() {
  return {
    access_token: Cookies.get("access_token"),
    refresh_token: Cookies.get("refresh_token"),
    token_type: Cookies.get("token_type"),
  };
}

export function removeAuthCookie() {
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
  Cookies.remove("token_type");
}

export function isAuthCookie() {
  return !!Cookies.get("access_token");
}

export function setNewToken(token) {
  Cookies.set("access_token", token);
}
import { Box, Avatar, Typography } from "@mui/material";
import iconChatBot from "assets/img/icon-chatbot.svg";

export function OtherMessage(mess, key) {
    return (
      <Box display="flex" key={key} flexDirection="column" py={1} justifyContent="start">
        <Box display="flex" height="min-content">
          <Avatar src={iconChatBot} />
          <Box
            bgcolor="#f5f5fa"
            padding={2}
            borderRadius="15px 15px 15px 15px"
            height="min-content"
          >
          <Typography
            style=  
              {{ whiteSpace: "pre-line",
              wordBreak: "break-word", fontSize: "0.95rem" }}
            >
              {mess}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
import { configIDRowTable, configTableOptions } from "utils/config-table";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import dayjs from "dayjs";

import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { adminApi } from "../adminsApi";

export function PublishedTable({ data, fetchGetAllBlogs }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [deletedId, setDeletedId] = useState(0);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const deletePost = () => {
    if (deletedId !== 0) {
      const toastId = toast.loading("Đang xử lý yêu cầu...", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      adminApi.deleteBlog(deletedId).then((res) => {
        if (res.status === 204) {
          fetchGetAllBlogs();
          toast.update(toastId, {
            render: "Xóa bài viết thành công",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          toast.update(toastId, {
            render: "Xóa bài viết không thành công",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        }
      });
      // .finally(() => {
      //   toast.dismiss(toastId);
      // });
      handleClose();
    }
  };

  const columns = [
    configIDRowTable("eventId", "", { page: 1 }, null),
    {
      name: "title",
      label: "Tên Bài Viết",
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th
              key={`head-th-${columnMeta.label}`}
              className="MuiTableCell-root MuiTableCell-head bg-[#BDE0FE]/50 text-primary"
              style={{
                // width: "30%",
                flex: 1,
                borderRight: "1px solid #0986CA",
              }}
            >
              <div>{columnMeta.label}</div>
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const blogId = data[tableMeta["rowIndex"]].id;
          return value ? (
            <span>
              <div
                className="hover:text-primary hover:underline"
                style={{ textAlign: "center" }}
              >
                <Link to={`/blogs/${blogId}`}>{value}</Link>
              </div>
            </span>
          ) : null;
        },
      },
    },
    {
      name: "created_at",
      label: "Thời Điểm Đăng Bài",
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th
              key={`head-th-${columnMeta.label}`}
              className="MuiTableCell-root MuiTableCell-head bg-[#BDE0FE]/50 text-primary"
              style={{
                // width: "20%",
                flex: 1,
                borderRight: "1px solid #0986CA",
              }}
            >
              <div>{columnMeta.label}</div>
            </th>
          );
        },
        customBodyRender: (value) => {
          return value ? (
            <span>
              <div className={value} style={{ textAlign: "center" }}>
                {dayjs(value).format("DD/MM/YYYY")}
              </div>
            </span>
          ) : null;
        },
      },
    },
    {
      name: "updated_at",
      label: "Thời Điểm Cập Nhật Bài",
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th
              key={`head-th-${columnMeta.label}`}
              className="MuiTableCell-root MuiTableCell-head bg-[#BDE0FE]/50 text-primary"
              style={{
                // width: "20%",
                flex: 1,
                borderRight: "1px solid #0986CA",
              }}
            >
              <div>{columnMeta.label}</div>
            </th>
          );
        },
        customBodyRender: (value) => {
          return value ? (
            <span>
              <div className={value} style={{ textAlign: "center" }}>
                {dayjs(value).format("DD/MM/YYYY")}
              </div>
            </span>
          ) : null;
        },
      },
    },
    {
      name: "action",
      label: "Thao tác",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div className="flex gap-4">
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setDeletedId(data[tableMeta["rowIndex"]].id);
                handleClickOpen();
              }}
              sx={{
                boxShadow: "none",
                borderRadius: "8px",
                width: "100px",
                textTransform: "none",
              }}
            >
              Xoá
            </Button>
            <Button
              className="hover:text-primary"
              variant="outlined"
              href={`/admin/blog-edit/${data[tableMeta["rowIndex"]].id}`}
              sx={{
                boxShadow: "none",
                borderRadius: "8px",
                width: "100px",
                textTransform: "none",
              }}
            >
              Chỉnh sửa
            </Button>
          </div>
        ),
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th
              key={`head-th-${columnMeta.label}`}
              className="MuiTableCell-root MuiTableCell-head bg-[#BDE0FE]/50 text-primary"
              style={{
                width: "100px",
                borderRight: "1px solid #0986CA",
              }}
            >
              <div>{columnMeta.label}</div>
            </th>
          );
        },
      },
    },
  ];

  const options = configTableOptions(10, 1, {
    page: 1,
  });

  return (
    <>
      <MUIDataTable
        title={""}
        data={data}
        columns={columns}
        options={options}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            Bạn có chắc chắn muốn xóa bài viết này?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Hủy
          </Button>
          <Button onClick={deletePost} autoFocus>
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

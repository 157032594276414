import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import "dayjs/locale/vi"; // Import locale tiếng Việt
import "dayjs/locale/en"; // Import locale tiếng Anh (hoặc các ngôn ngữ khác nếu cần)

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

export const formatDateStringToLocalDate = (
  dateString,
  format,
  lang = "en"
) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Thiết lập ngôn ngữ
  dayjs.locale(lang);

  // Định dạng ngày tháng
  if (lang === "vi") {
    return dayjs.utc(dateString).tz(userTimeZone).format(format);
  } else {
    // Định dạng mặc định cho tiếng Anh (hoặc ngôn ngữ khác)
    return dayjs.utc(dateString).tz(userTimeZone).format(format);
  }
};

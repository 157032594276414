export default {
  /*** home page ***/
  home_page_title: "Home",
  banner_title_1: "Innovative Tech",
  banner_title_2: "Solution Partners",
  detail_btn: "Detail",
  title_offer: "What we offer",
  explore_text: "Explore Our Offer For Modern Technology Solutions",
  read_time: "minute read",
  related_blogs: "related blogs",

  /*** about page ***/
  about_page_title: "About",
  about_aivision: "ABOUT AI VISION",
  about_page_description:
    "We are AIVision! A leader in the application of AI technology to the retail and supermarket industry. With a focus on providing innovative solutions that de-liver values to our customers, we have developed two cutting-edge products that set us apart from the competition. Our AI Retail Vision system gives re-tailers unprecedented insights into customer behaviors and preferences, while our smart HR system uses AI to evaluate job candidates and ensure the right fit for the job. With a team of expert researchers and developers, including many PHD researchers in AI, we are committed to harnessing the power of AI to transform the retail industry and bring values to our customers.​​",

  our_team: "Our Team",
  team_grid: "Our Leaders",

  our_client: "Our Client",
  client_grid: "Clients",


  /*** services page ***/
  services_page_title: "Services",
  discover_services_title: "Let’s Discover Our Services!",
  discover_services_description:
    "AI technologies encompass a wide range of tools and techniques that use artificial intelligence algorithms and machine learning models to process and analyze data. At AIVision, we provide four main services currently.​",
  read_more: "Read more",
  hidden: "Hidden",
  need_help: "Need Any Help?",
  /*** blog page ***/
  blog_page_title: "Blog",
  /*** contact page ***/
  contact_page_title: "Contact",

  /*** contact component ***/
  contact_box_description: "Let us know your story!",
  contact_box_title: "Contact Us",
  contact_box_name: "Your name",
  contact_box_email: "Email",
  contact_box_message: "Message",
  send: "Send",
  information: "Information",

  /*** footer component ***/
  about_company: "About Company",
  footer_about_company_description:
    "AIVision harnesses the transformative potential of AI technology, creating innovative solutions that empower businesses, drive efficiency, and deliver exceptional value, all while fostering ethical and responsible AI practices.​",
  quick_link: "Quick Link",
  our_services: "Our Services",
  our_faq: "​​​​​Our FAQ’s​",
  meet_team: "Meet the Team",
  news_media: "News & Media",
  contact_us: "Contact Us",
  newsletter: "Newsletter",
  newsletter_description:
    "At AIVision, we are constantly innovating new AI applications and technologies to bring as much as values to our lives. We believe that AI has the potential to transform the way we live and work, and we are excited to be a part of this journey.​",

  /*** 4 services ***/
  ai_service_title: "AI Vision Platform",
  ai_service_description:
    "Scoring system for display, counting the company's product categories, and understanding market share, images, and products of the same type to come up with business strategies, specializing in realistic image collages by artificial intelligence and technology.​<br/>Functions:​<br/>- Accurately recognize and count products displayed on shelves.​<br/>- Precisely detect pricing and promotion events.​<br/>- Gain valuable insights on target products and competitors.​<br/>- Real-time processing.​<br/>- High performance.​",

  chatbot_service_title: "Chatbot",
  chatbot_service_description:
    "A Chatbot technology system is a tool or method of internal and external communication and sharing of work interaction, face-to-face processing, intelligent online by artificial intelligence support, high security in business communication, and other new applications at the request of customers.​<br/>Functions:​<br/>- Assist customers 24/7.​<br/>- Respond accurately and quickly.​<br/>- Automatically book and make reservations.​<br/>- Retrieve information from your customers.​<br/>- Support any language.<br/>- Train and provide answers from your secure knowledge.​",

  smart_hr_service_title: "Smart HR Platform",
  smart_hr_service_description:
    "An artificial intelligence-enhanced “system” that filters job applications, providing results of candidates suitable for various jobs in recruitment, allows editing of application forms according to new templates, and scientifically stores it all in HR data banks by artificial intelligence. Recruitment departments or process in a company that deals with hiring, training, managing employees, etc.​<br/>Functions:​<br/>- CV information extraction.​<br/>- Job description analysis.​<br/>- Ranking candidates based on JD and custom rules.​<br/>- AI Recommendation.​<br/>- API integration.​",

  data_analytics_service_title: "Data Analytics Platform",
  data_analytics_service_description:
    "Intelligent data analysis system produces accurate results by artificial intelligence and technology.​<br/>Functions:​<br/>- Get Insights From Your Data.​<br/>- Data Expandable And Learnable.​<br/>- Provide Analytics Reports And Recommendation From Data Scientist Team.​<br/>- Provide Data Warehouse.​<br/>- ETL Platform.​<br/>- Dashboard Available On Web and Your Mobile.​",

  /********* case study ***********/
  /********* ai ***********/
  ai_retail_title: "AI in Retail",
  ai_retail_description_1:
    "Retailers used AI vision to optimize its store layout and improve customer experience. The retailers had been struggling with low foot traffic and declining sales, and was looking for ways to attract more customers and increase revenue.",
  ai_retail_description_2:
    "Our application identified that customers frequently stopped to look at a certain product, but the product was not prominently displayed. So, store owners moved the products to more visible location, resulting in a significant increase in sales.",

  ai_monitoring_title: "AI Vision in Shelf Monitoring",
  ai_monitoring_description_1:
    "A grocery store chains wanted to improve shelf monitoring process to ensure that products were always in stock and properly displayed. They had been struggling with out-of-stock items and poor product placement, leading to lost sales and dissatisfied customers.",
  ai_monitoring_description_2:
    "The store chain implemented an AI vision system that uses machine learning algorithms to analyze images of store shelves in real-time. Then, our AI Vision Application would analyze captured images to identify out-of-stock items, misplaced products, and other issues.",

  ai_competition_title: "AI Vision in Product Competition",
  ai_competition_description_1:
    "Consumer goods manufacturers wanted to better understand how its products were being displayed in stores and how they were performing compared to competitors.",
  ai_competition_description_2:
    "Our system would provide insights into how the manufacturer's products were being displayed compared to competitors, allowing the manufacturer to optimize its product placement and marketing strategies.",

  /********* chatbot ***********/
  chatbot_customer_title: "Chatbot - 24/7 Customer Service",
  chatbot_customer_description:
    "If a business needs to provide 24/7 customer service, implementing a Chatbot can provide customers with immediate assistance for routine queries, such as account information, order status, and product details. For more complex queries, the Chatbot can escalate the query to a human customer service agent, ensuring that customers receive a timely and accurate response.",

  chatbot_queries_title: "Chatbot in High Volume of Customer Queries",
  chatbot_queries_description_1:
    "When a business receives a high volume of customer queries, it can be a challenge to respond to each query in a timely and efficient manner. This can result in frustrated customers, reduced customer satisfaction, and lost sales.",
  chatbot_queries_description_2:
    "Implementing a chatbot can help businesses save costs because hiring additional customer service agents to handle a high volume of customer queries can be expensive, also can reduce the workload for human customer service agents and automate routine tasks.",

  chatbot_recommendation_title: "Personalized Recommendations",
  chatbot_recommendation_description:
    "Chatbots use machine learning algorithms to analyze user data and make predictions about what products or services the user is most likely to be interested in. Chatbots can use natural language processing (NLP) to understand user queries and provide relevant responses, as well as contextual awareness to provide personalized recommendations based on factors such as location, weather, or time of day.",

  /*********** data platform *****************/
  data_platform_title:
    "Data Analytics Platform in Improving Customer Experience",
  data_platform_description:
    "Sellers found that many customers were abandoning their shopping carts before completing the purchase. By analyzing the data on Data Analytics Platform, they identified the reasons for cart abandonment, such as high shipping costs and a complicated checkout process. Then, they made changes to their website to address these issues, resulting in a significant increase in completed purchases.",

  data_inventory_title:
    "Identify bottlenecks, Optimize inventory levels, and Reduce costs",
  data_inventory_description:
    "Using Data Analytics to identify bottlenecks, optimize inventory levels, and reduce costs​ Whatever global manufacturing companies with a complex supply chain involving multiple suppliers, warehouses, and distribution centers can apply Data analytics Platform to reduced inventory carrying costs by 20% by optimizing safety stock levels, Reduced stockouts by 30% through improved demand forecasting and inventory planning, etc.",

  data_social_title: "Analyze Social Media Data",
  data_social_description_1:
    "Sale managers was experiencing a decline in sales and a decrease in customer engagement. They noticed negative comments and feedback about their products and services on social media platforms, and they wanted to understand the root cause of these issues.",
  data_social_description_2:
    "To solve these problems, Data Analytics Platform performed advanced analytics, including sentiment analysis and topic modeling, to identify customer sentiment and trending topics related to their brand and products.",

  /*********** smart HR *****************/
  hr_extraction_title: "CV Information Extraction",
  hr_extraction_description_1:
    "HR departments struggle with numerous resumes and they want to save a significant amount of time of extracting information from resumes.",
  hr_extraction_description_2:
    "In this case, Smart HR Platform can use resume parsing technology to extract information from Curriculum Vitae submitted by applicants. Resume parsing involves using machine learning algorithms to extract relevant information from resumes, such as work experience, education and skills, and storing it in a structured format for further processing.",

  hr_interview_title: "Get Interview Insights on Social Media Profiles",
  hr_interview_description_1:
    "Social media profiles can offer additional insights into candidates beyond what is typically included in resumes or application forms.",
  hr_interview_description_2:
    "Extracting information from social media profiles by implementing Smart HR Platform, HR departments can gather a more comprehensive view of candidates' skills, interests, and professional experiences, which can help in assessing their suitability for specific roles.",

  hr_job_title: "Analyze Job Description",
  hr_job_description_1:
    "If your company wants to bring efficiency, accuracy, and improvement to the hiring process, let implement our Smart HR Platform.",
  hr_job_description_2:
    "By analyzing job descriptions, Smart HR Platform can identify the most relevant keywords, skills, and qualifications needed for a specific role. This ensures that the job description accurately reflects the requirements of the position, helping HR departments attract candidates who possess the necessary skills and experience.",

  /******** Blog Box ********/
  blog_1_title: "See the Future with AI Vision",
  blog_1_description:
    "Our cutting-edge AI technology can help you predict trends, identify opportunities, and stay ahead of the competition. Whether you're in retail, finance, or any other industry, AI vision can give you the insights you need to make smarter decisions and grow your business.",
  blog_1_date: "July 01, 2023",

  blog_2_title: "Revolutionize Your Operations with AI Vision",
  blog_2_description:
    "From supply chain management to quality control, AI vision can help you optimize your operations and improve efficiency. Our AI-powered tools can analyze data in real-time, detect anomalies, and suggest improvements, giving you the ability to make data-driven decisions and reduce costs.",
  blog_2_date: "June 01, 2023",

  blog_3_title: "Revolutionize Your HR Processes with Our Smart HR Platform",
  blog_3_description:
    "​​Our platform uses cutting-edge technology to streamline your HR processes and improve efficiency. From recruitment to onboarding to performance management, our platform can help you save time and reduce costs while improving the employee experience.",
  blog_3_date: "May 01, 2023",

  blog_4_title: "Unlock the Power of Your Data with Our Analytics Platform",
  blog_4_description:
    "Our platform can help you turn your data into actionable insights. With powerful analytics tools and intuitive dashboards, you can visualize your data in new ways and make data-driven decisions that drive business growth.",
  blog_4_date: "April 01, 2023",

  blog_5_title: "Streamline Your Data Analytics with Our Platform",
  blog_5_description:
    "Our platform makes it easy to collect, manage, and analyze your data in one place. From data integration to data modeling to data visualization, our platform provides a seamless and efficient analytics workflow.",
  blog_5_date: "March 01, 2023",

  blog_6_title: "Engage Your Customers 24/7 with Our AI Chatbot",
  blog_6_description: "Engage Your Customers 24/7 with Our AI Chatbot",
  blog_6_date: "February 01, 2023",
  alert_copied: "Copied link!"
};

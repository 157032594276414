import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AdminTopBar } from "../components/AdminTopBar";
import { isAuthCookie } from "utils/auth-cookie";

export const AdminLayout = () => {
  const navigate = useNavigate();
  const isLoggin = isAuthCookie();
  useEffect(() => {
    console.log("isLoggin", isLoggin);
    if (!isLoggin) {
      navigate("/login");
    }
  }, [isLoggin, navigate]);

  return (
    <div className="flex w-full h-[calc(100vh-0px)] overflow-auto">
      <div className="flex flex-col flex-1 h-full max-h-screen overflow-auto scrollbar">
        <AdminTopBar />
        <div className="mt-24 w-max md:w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

import { chatbotAxiosClient } from "utils/axiosClient";
import { eventChannel } from 'redux-saga';
const CHATBOT_URL_ROOT = "https://aivision-chatbotai.ap.ngrok.io/";

export const chatbotApi = {
    messageToBot({
        sessionId,
        message,
        combined_id
    }) {
        // Use combine_id to classify type of meeting and confirm booking
        if (combined_id) {
            message = message + `@combined_id=${sessionId}`
        }
        return eventChannel(emitter => {
            // Subscribe to the event source and emit events as necessary
            const events = new EventSource(`${CHATBOT_URL_ROOT}chat/${sessionId}/message?question=${encodeURI(message)}`);
            events.onmessage = (event) => {
                const parsedData = JSON.parse(event.data);
                // Emit the event to the Event Channel
                emitter(parsedData);
            }
            // Return the cleanup function to be called when the channel is closed
            return () => {
                events.close();
            };
        });
    },

    fetchHistoryMessages(id, limit, offset) {
        return chatbotAxiosClient.get(`chat/${id}/history?offset=${offset}&limit=${limit}&`);
    },

    fetchWelcomeMessage(id) {
        return chatbotAxiosClient.get(`chat/${id}/welcome`);
    },

    getSessionId() {
        return chatbotAxiosClient.get("auth/session");
    },

    cancelAllBookings(id) {
        return chatbotAxiosClient.get(`booking/cancel/${id}`)
    },
}
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function MaintenanceModal({
  anchorEl,
  handleOpenChat,
  handleCloseChat,
}) {
  const open = Boolean(anchorEl);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleCloseChat}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={{...style, justifyContent: 'center', alignItems: 'center'}}>
          <Typography align='center' color="#0060df" id="transition-modal-title" variant="h6" component="h2">
            Under Maintenance
          </Typography>
          <Typography align='center' id="transition-modal-description" sx={{ mt: 2 }}>
            This feature is under maintenance
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
}

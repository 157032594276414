import { createSlice } from "@reduxjs/toolkit";
import {
  removeAuthCookie,
  setAuthCookie,
  setNewToken,
} from "utils/auth-cookie";

const initialState = {
  currentUser: {
    access_token: "",
    refresh_token: "",
    token_type: "",
  },
  isLogin: false,
  isLoading: false,
  errormsg: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state) => {
      state.isLoading = true;
    },
    logout: (state) => {
      state.currentUser = initialState.currentUser;
      state.isLogin = false;
      state.isLoading = false;
      state.errormsg = "";
      removeAuthCookie();
    },
    loginSuccess: (state, action) => {
      state.isLogin = true;
      state.isLoading = false;
      state.currentUser = action.payload;
      setAuthCookie(action.payload);
    },
    loginFailed: (state, action) => {
      state.isLogin = false;
      state.isLoading = false;
      state.currentUser = initialState.currentUser;
      state.errormsg = action.payload;
      removeAuthCookie();
    },
    refreshToken: (state, action) => {
      state.currentUser.access_token = action.payload;
      setNewToken(action.payload);
    },
    getAuth: (state) => {
      console.log(state);
    },
  },
});

export const authAction = authSlice.actions;

export const selectIsLoging = (state) => state.auth.isLogin;

const authReducer = authSlice.reducer;
export default authReducer;

import { axiosClient } from "utils/axiosClient";
import { API_URL_ROOT, IMAGE_URL_ROOT } from "utils/constant";

export class CustomUploader {
  loader; // Add loader property
  uploadImageRequestUrl;
  post_id;

  constructor(loader, uploadImageRequestUrl, post_id) {
    this.loader = loader;
    this.uploadImageRequestUrl = uploadImageRequestUrl;
    this.post_id = post_id;
  }

  upload = async () => {
    try {
      const file = await this.loader.file;
      //   console.log("file", file);

      const formData = new FormData();
      if (file) {
        formData.append("file", file);
      }

      const response = await axiosClient.post(
        this.uploadImageRequestUrl,
        formData,
        {
          params: this.post_id
            ? {
                post_id: this.post_id,
              }
            : {},
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return {
        default:
          IMAGE_URL_ROOT +
          response?.data?.image_url?.replace("static/images/", ""),
      };
    } catch (error) {
      throw error;
    }
  };

  abort() {
    console.log("Upload aborted", this.loader.file);
  }
}

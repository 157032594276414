import { BrowserRouter, useRoutes } from "react-router-dom";
import HomePage from "./pages/HomePage";
// import css libs
import "./assets/sass/style.css";
// import "./assets/sass/style.scss"
import { BlogList, DetailPage } from "pages/Blogs/pages";
import { I18nextProvider } from "react-i18next";
import Menu from "./components/Menu";
import i18n from "./i18n/i18n";
import ContactPage from "./pages/Contact";
import ProductsPage from "./pages/Products";
import ServiceDetail from "./pages/ServiceDetail";
import TeamPage from "./pages/Team";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import FabGroup from "components/FabGroup";
import ScrollToTop from "components/ScrollToTop";
import { adminRoutes } from "pages/Admin/routes";
import { authRoutes } from "pages/Auth/routes";
import { Provider } from "react-redux";
import { store } from "redux/store";
import { ToastContainer } from "react-toastify";
import { StyledEngineProvider } from "@mui/material";
import ThemeRegistry from "ThemeRegistry";
import "aos/dist/aos.css"; // Nhập CSS của AOS
import AOS from "aos";

AOS.init({
  duration: 1000,
  mirror: true
});
function Layout({ children, tab }) {
  return (
    <>
      <Menu currentTab={tab} />
      {children}
      <FabGroup />
    </>
  );
}

function Common() {
  let routes = useRoutes([
    {
      path: "/",
      element: (
        <Layout tab={0}>
          <HomePage />
        </Layout>
      ),
    },
    {
      path: "/services",
      element: (
        <Layout tab={2}>
          <ProductsPage />
        </Layout>
      ),
    },
    {
      path: "/about-us",
      element: (
        <Layout tab={1}>
          <TeamPage />
        </Layout>
      ),
    },
    {
      path: "/contact",
      element: (
        <Layout tab={4}>
          <ContactPage />
        </Layout>
      ),
    },
    {
      path: "/blogs",
      element: (
        <Layout tab={3}>
          <BlogList />
        </Layout>
      ),
    },
    {
      path: "/blogs/:id",
      element: (
        <Layout tab={3}>
          <DetailPage />
        </Layout>
      ),
    },
    {
      path: "/services/detail/:serviceId",
      element: (
        <Layout tab={-1}>
          <ServiceDetail />
        </Layout>
      ),
    },
    ...authRoutes(),
    ...adminRoutes(),
  ]);
  return routes;
}

export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeRegistry options={{ key: "mui" }}>
            <I18nextProvider i18n={i18n} defaultNS={"translation"}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="vi"
              >
                <Common />
              </LocalizationProvider>
              <ToastContainer />
              <ScrollToTop />
            </I18nextProvider>
          </ThemeRegistry>
        </BrowserRouter>
      </Provider>
    </StyledEngineProvider>
  );
}

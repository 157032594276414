import axios from "axios";
import { authAction } from "pages/Auth/saga/authSlice";
import { store } from "redux/store";
import { getAuthCookie, setNewToken } from "./auth-cookie";
import { API_URL_ROOT, CHATBOT_URL_ROOT } from "./constant";

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {

    OriginReferer: window.location.origin,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Credentials": "true",
    "Accept": "application/json",
  },
});

axiosClient.interceptors.request.use(
  function (config) {
    const { access_token } = getAuthCookie();
    config.headers.Authorization = `Bearer ${access_token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // store.dispatch(authAction.logout());
        const { refresh_token } = getAuthCookie();
        const response = await axios.post(
          "/users/refresh",
          {},
          {
            params: {
              refresh_token,
            },
          }
        );
        const { access_token } = response.data.data;
        setNewToken(access_token);
        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return axios(originalRequest);
      } catch (error) {
        store.dispatch(authAction.logout());
        // Handle refresh token error or redirect to login
      }
    }

    // return Promise.reject(error);
    return error.response;
  }
);

export const chatbotAxiosClient = axios.create({
  baseURL: CHATBOT_URL_ROOT,
  headers: {
    "Content-Type": "application/json",
    OriginReferer: window.location.origin,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Credentials": "true",
  },
});

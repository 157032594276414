// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-next:before {
  display: none;
}
.slick-prev:before {
  display: none;
}
.slick-prev {
  left: auto;
  right: 50px;
  top: -20px;
}

.slick-next {
  right: 0px;
  top: -20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Team/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,UAAU;EACV,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,UAAU;AACZ","sourcesContent":[".slick-next:before {\n  display: none;\n}\n.slick-prev:before {\n  display: none;\n}\n.slick-prev {\n  left: auto;\n  right: 50px;\n  top: -20px;\n}\n\n.slick-next {\n  right: 0px;\n  top: -20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import { rootReducer } from "./rootReducer";
import { chatbotAction } from "../components/CustomChatWidget/saga/chatbotSlice";

const saga = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [saga],
});
saga.run(rootSaga);

// window.addEventListener('beforeunload', () => {
//   const { chatbot } = store.getState();
//   store.dispatch(chatbotAction.deactivateSession(chatbot.session_id.id));
// });

export { store };

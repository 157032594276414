export const language = [
  {
    label: "VN",
    value: "vi",
  },
  {
    label: "EN",
    value: "en",
  },
];

import { Clear } from "@mui/icons-material";

import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ImageListItem = ({ index, imagePath, onDelete, onUpdate, disabled }) => {
  return (
    <ListItem key={index}>
      {/* <Chip label={name} icon={<UploadFileIcon />} variant="outlined" sx={{ maxWidth: 200 }} onDelete={onDelete} /> */}
      <Box
        position="relative"
        className="w-[300px] aspect-video"
        border={1}
        borderColor="divider"
      >
        <Box
          width="100%"
          height="100%"
          component="img"
          src={imagePath}
          sx={{ objectFit: "contain", maxWidth: "100%" }}
        />
        <Box position="absolute" top={5} right={5}>
          <IconButton
            size="small"
            sx={{
              bgcolor: "rgba(0,0,0,0.5)",
              "&:hover": {
                bgcolor: "rgba(0,0,0,0.7)",
              },
            }}
            disabled={disabled}
            onClick={() => onDelete(index)}
          >
            <Clear
              style={{
                color: "white",
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </ListItem>
  );
};

export default ImageListItem;

import { takeEvery, call, put, takeLatest } from "redux-saga/effects";
import { blogsAction } from "./blogSlice";
import { blogsApi, categoriesApi } from "../blogsApi";
import { adminApi } from "pages/Admin/adminsApi";

export function* getAllBlogss(action) {
  try {
    const response = yield call(adminApi.getAllBlogs, action.payload);
    if (response && response.status === 200) {
      yield put(blogsAction.getBlogsSuccess(response.data));
    } else {
      console.log("Failed to fetch blog list", response.message);
      yield put(blogsAction.getBlogsFailed(response.message));
    }
  } catch (error) {
    yield put(blogsAction.getBlogsFailed(error.message));
  }
}

export function* getBlogDetail(action) {
  try {
    const response = yield call(adminApi.getBlogBySlug, action.payload);
    if (response && response.status === 200) {
      yield put(blogsAction.getBlogDetailSuccess(response.data));
    } else {
      console.log("Failed to fetch blog detail", response.message);
      yield put(blogsAction.getBlogDetailFailed(response.message));
    }
  } catch (error) {
    yield put(blogsAction.getBlogDetailFailed(error.message));
  }
}

export function* getAllCategories(action) {
  try {
    const response = yield call(categoriesApi.getAll, action.payload);
    if (response && response.status === 200) {
      yield put(blogsAction.getCategoriesSuccess(response.data));
    } else {
      console.log("Failed to fetch blog list", response.message);
      yield put(blogsAction.getCategoriesFailed(response.message));
    }
  } catch (error) {
    yield put(blogsAction.getCategoriesFailed(error.message));
  }
}

export default function* blogsSaga() {
  yield takeLatest(blogsAction.getBlogs, getAllBlogss);
  yield takeLatest(blogsAction.getBlogDetail, getBlogDetail);
  yield takeLatest(blogsAction.getCategories, getAllCategories);
}

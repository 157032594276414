import { Box, Grid, Typography } from "@mui/material";
import { ImageHandle } from "components/ImageHandler";
import { t } from "i18next";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import format from "date-fns/format";
import blogBanner from "assets/img/blog_1.jpg";
import { API_URL_ROOT } from "utils/constant";

export default function BoxBlog({ img, description, title, link, date }) {
  const navigate = useNavigate();
  const [isExpand, setIsExpand] = useState(false);

  const dataVal = Date.parse(date);
  var formatedDate = format(dataVal, "MMMM dd, yyyy");
  console.log("BoxBlog -> formatedDate", API_URL_ROOT + "/" + img);
  return (
    <Box
      sx={{
        width: "300px",
        maxHeight: isExpand ? "auto" : "515px",
        borderRadius: "10px",
        boxShadow: "5px 5px 3px #c3c0c0",
        overflow: "hidden",
        background: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ImageHandle
        src={API_URL_ROOT + "/" + img}
        defaultSrc={blogBanner}
        style={{ width: "100%", height: "200px" }}
      />
      <Box
        sx={{
          margin: "10px",
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            marginTop: "20px",
            marginBottom: "10px",
            // height: "35px",
            // textOverflow: "ellipsis",
            // overflow: "hidden",
            // whiteSpace: "nowrap",
            minHeight: "90px",
            height: isExpand ? "auto" : "90px",
            textOverflow: "ellipsis",
            overflow: isExpand ? "unset" : "hidden",
            display: isExpand ? "block" : "-webkit-box",
            // textAlign: "justify",
          }}
          component={Link}
          to={`${link}`}
          className="multiline-ellipsis"
        >
          {title}
        </Typography>
        <Box flexDirection="column" display="flex" flex="auto" />
        <Typography
          sx={{
            fontSize: "16px",
            marginBottom: "10px",
            height: isExpand ? "auto" : "145px",
            textOverflow: "ellipsis",
            overflow: isExpand ? "unset" : "hidden",
            display: isExpand ? "block" : "-webkit-box",
            textAlign: "justify",
          }}
          className="multiline-ellipsis"
        >
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontWeight: "400", fontStyle: "italic" }}>
            {formatedDate}
          </Typography>
          {description && description != "" && (
            <Typography
              onClick={() => setIsExpand((old) => !old)}
              sx={{ cursor: "pointer", fontWeight: "600" }}
            >
              {isExpand ? t("hidden") : t("read_more")}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

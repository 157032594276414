import {
    Box,
    Typography,
    Button,
} from "@mui/material";

export function MsgButton(msg1, msg2, handleClick) {
    return (
        <Box padding={1}
        display="flex"
        justifyContent="center"
    >
    <Button
        style={{ textTransform: "none" }}
        sx={{ 
            border: "1px solid",
            marginTop: 1, 
            borderRadius: "15px" 
        }}
        variant="outlined"
        size="large"
        onClick={() => handleClick(msg1)}
    >
        <Typography align="left" sx={{ fontSize: "0.85rem", fontWeight: "bold" }}>
        👍 {msg1}
        </Typography>
    </Button>
    <Button 
        style={{ textTransform: "none" }}
        sx={{
            border: "1px solid",
            marginTop: 1,
            marginLeft: 5,
            borderRadius: "15px"
        }}
        size="large"
        variant="outlined"
        color="error"
        onClick={() => handleClick(msg2)}
    >
        <Typography align="left" sx={{ fontSize: "0.85rem", fontWeight: "bold" }}>
        👎 {msg2}
        </Typography>
    </Button>
    </Box>
    );
}
import {
  takeLatest,
  call,
  put,
  take,
} from "redux-saga/effects";
import { chatbotAction } from "./chatbotSlice";
import { chatbotApi } from "../chatbotApi";

export function* getSessionId(action) {
  try {
    const response = yield call(chatbotApi.getSessionId);
    if (response && response.status === 200) {
      yield put(chatbotAction.getSessionIdSuccess(response.data));
    } else {
      console.log("Failed to fetch session id", response.message);
      yield put(chatbotAction.getSessionIdFailed(response.message));
    }
  } catch (error) {
    yield put(chatbotAction.getSessionIdFailed(error.message));
  }
}

export function* sendFirstMsg(action) {
  try {
    const response = yield call(chatbotApi.getSessionId);
    if (response && response.status === 200) {
      yield put(chatbotAction.getSessionIdSuccess(response.data));
      yield put(chatbotAction.getChatbotMessage({
        sessionId: response.data.id,
        message: action.payload.message,
        combined_id: action.payload.combined_id,
      }));
    } else {
      console.log("Failed to fetch session id", response.message);
      yield put(chatbotAction.getSessionIdFailed(response.message));
    }
  } catch (error) {
    yield put(chatbotAction.getSessionIdFailed(error.response.data.detail));
  }
}

export function* getChatbotMessage(action) {
  try {
    const events = yield call(chatbotApi.messageToBot, action.payload);
    while (true) {
      const message = yield take(events);
      if (message) {
        yield put(chatbotAction.getStreamChatbotMessage(message));
      }
      if (message.status === "SUCCESS" || message.status === "ERROR") {
        events.close();
        yield put(chatbotAction.finishLoadingMessage());
        break;
      }
    }
  } catch (error) {
    console.log('Error in getChatbotMessage', error.message)
    yield put(chatbotAction.getChatbotMessageFailed(error.message));
  }
}

export function* fetchHistoryMessages(action) {
  try {
    const response = yield call(chatbotApi.fetchHistoryMessages, 
      action.payload.id, action.payload.limit, action.payload.offset);
    if (response && response.status === 200) {
      yield put(chatbotAction.successFetchingHistory(response.data));
    } else {
      console.log("Failed to fetch history messages", response.message);
      yield put(chatbotAction.failed(response.message));
    }
  } catch (error) {
    yield put(chatbotAction.failed(error.message));
  }
}

export function* fetchWelcomeMessages(action) {
  try {
    const response = yield call(chatbotApi.fetchWelcomeMessage, action.payload.id);
    if (response && response.status === 200) {
      yield put(chatbotAction.successFetchingWelcomeMessage(response.data));
    } else {
      console.log("Failed to fetch welcome messages", response.message);
      yield put(chatbotAction.failed(response.message));
    }
  } catch (error) {
    yield put(chatbotAction.failed(error.message));
  }
}

export function* cancelAllBookings(action) {
  try {
    if (!action.payload.id) {
      return;
    }
    const response = yield call(chatbotApi.cancelAllBookings, action.payload.id);
    if (response && response.status === 200) {
      yield put(chatbotAction.finishLoadingMessage(response.data));
    } else {
      console.log("Failed to cancel bookings", response.message);
      yield put(chatbotAction.failed(response.message));
    }
  } catch (error) {
    yield put(chatbotAction.failed(error.message));
  }
}


export default function* chatbotSaga() {
  yield takeLatest(chatbotAction.getSessionId, getSessionId);
  yield takeLatest(chatbotAction.getChatbotMessage, getChatbotMessage);
  yield takeLatest(chatbotAction.cancelAllBookings, cancelAllBookings);
  yield takeLatest(chatbotAction.sendFirstMsg, sendFirstMsg);
  yield takeLatest(chatbotAction.fetchHistoryMessages, fetchHistoryMessages);
  yield takeLatest(chatbotAction.fetchWelcomeMessage, fetchWelcomeMessages);
}

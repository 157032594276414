import { useState, useEffect } from "react";

import { Box, Fade, Slide, Tooltip, Zoom, key } from "@mui/material";

import CustomChatWidget from "components/CustomChatWidget";
import BackToTopButton from "components/BackToTopButton";

const btnList = [<CustomChatWidget />, <BackToTopButton />];

export default function FabGroup() {
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowBackToTop(scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        // transition: "bottom 0.3s ease",
      }}
    >
      <BackToTopButton show={showBackToTop} />
      <CustomChatWidget />
    </Box>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sessionActive: false,
    session_id: {
        created_at: null,
        id: null,
        isActive: false,
        updated_at: null,
    },
    historyMsg: [],
    welcomeMessage: {
        messages: null,
        recommends: [],
    },
    messageReceived: null,
    isLoading: false,
    isStartStreaming: null,
    errormsg: "",
    isFetching: false,
};

export const chatbotSlice = createSlice({
    name: "chatbot",
    initialState: initialState,
    reducers: {
        sendFirstMsg: (state, action) => {
            state.isLoading = true;
        },
        getSessionId: (state) => {
            state.isLoading = true;
        },
        getSessionIdSuccess: (state, action) => {
            state.session_id = action.payload;
            state.isLoading = false;
        },
        getSessionIdFailed: (state, action) => {
            state.errormsg = action.payload;
            state.isLoading = false;
        },
        // message
        getChatbotMessage: (state, action) => {
            state.isLoading = true;
            state.isStartStreaming = false;
        },
        getStreamChatbotMessage: (state, action) => {
            state.messageReceived = action.payload;
            state.isStartStreaming = true;
        },
        finishLoadingMessage: (state, action) => {
            state.isLoading = false;
        },
        getChatbotMessageFailed: (state, action) => {
            state.errormsg = action.payload;
            state.isLoading = false;
        },
        clearErrorMessage: (state, action) => {
            state.errormsg = "";
        },
        // cancel all bookings
        cancelAllBookings: (state, action) => {
            state.isLoading = true;
        },
        
        fetchHistoryMessages: (state, action) => {
            state.isFetching = true;
        },
        fetchWelcomeMessage: (state, action) => {
            state.isFetching = true;
        },
        successFetchingWelcomeMessage: (state, action) => {
            state.welcomeMessage = action.payload;
            state.isFetching = false;
        },
        successFetchingHistory: (state, action) => {
            state.historyMsg = action.payload;
            state.isFetching = false;
        },
        success: (state, action) => {
            state.messageReceived = action.payload;
            state.isStartStreaming = true;
            state.isLoading = false;
        },
        failed: (state, action) => {
            state.errormsg = action.payload;
        },
    }
});

export const chatbotAction = chatbotSlice.actions;

export const selectSessionId = (state) => state.chatbot.session_id;
export const selectReceiveMessage = (state) => state.chatbot.messageReceived;
export const selectButtonRecomMessage = (state) => state.chatbot.buttonRecomMessage;
export const selectErrorMessage = (state) => state.chatbot.errormsg;
export const selectIsFetching = (state) => state.chatbot.isFetching;
export const selectHistoryMsg = (state) => state.chatbot.historyMsg;
export const selectWelcomeMessage = (state) => state.chatbot.welcomeMessage;

const chatbotReducer = chatbotSlice.reducer;
export default chatbotReducer;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-image-preview-operations-operation {
  display: none;
}
.ant-image-mask{
  background: transparent!important;
}
.ant-image-mask-info{
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomChatWidget/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,iCAAiC;AACnC;AACA;EACE,UAAU;AACZ","sourcesContent":[".ant-image-preview-operations-operation {\n  display: none;\n}\n.ant-image-mask{\n  background: transparent!important;\n}\n.ant-image-mask-info{\n  opacity: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Box, Grid, Typography } from "@mui/material";
import instagramBlackIcon from "../../assets/img/icon-instagram-black.svg";
import instagramRedIcon from "../../assets/img/icon-instagram-red.svg";
import fbBlackIcon from "../../assets/img/icon-fb-black.svg";
import fbRedIcon from "../../assets/img/icon-fb-red.svg";
import twitterBlackIcon from "../../assets/img/icon-twitter-black.svg";
import twitterRedIcon from "../../assets/img/icon-twitter-red.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
export default function TeammateBox({ avatar, name, role }) {
  const navigate = useNavigate();
  const [isHover, setIsHover] = useState(false);

  return (
    <Box
      sx={{
        width: "300px",
        height: "300px",
        padding: "15px 15px",
        margin: "10px",
        cursor: "pointer",
        color: isHover ? "white" : "black",
        filter: "drop-shadow(5px 6px 3px #c3c0c0)",
      }}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <Box
        sx={{
          background: isHover ? "#d61f23" : "white",
          clipPath: "polygon(0% 10%, 100% 35%, 100% 100%, 0% 100%)",
          position: "absolute",
          width: "100%",
          height: "100%",
          bottom: 0,
          left: 0,
          borderRadius: "5px",
        }}
      />
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            border: "1px solid #c3c0c0",
            borderRadius: "50%",
            padding: "5px",
          }}
        >
          <img
            src={avatar}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              objectFit: "cover",
              objectPosition: "top",
            }}
          />
        </Box>

        <Typography
          sx={{ fontSize: "20px", marginTop: "20px", marginBottom: "10px" }}
        >
          {name}
        </Typography>
        <Typography
          sx={{ fontSize: "16px", marginBottom: "10px", textAlign: "center" }}
        >
          {role}
        </Typography>
        <Box display={"flex"} justifyContent={"center"}>
          <img
            src={isHover ? fbRedIcon : fbBlackIcon}
            style={{ width: "30px", borderRadius: "50%" }}
          />
          <img
            src={isHover ? twitterRedIcon : twitterBlackIcon}
            style={{ width: "30px", borderRadius: "50%" }}
          />
          <img
            src={isHover ? instagramRedIcon : instagramBlackIcon}
            style={{ width: "30px", borderRadius: "50%" }}
          />
        </Box>
      </Box>
    </Box>
  );
}

import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { Divider, Pagination, Box, Button } from "@mui/material";
import { adminApi } from "../adminsApi";
import { PublishedTable } from "../components/PublishedTable";
import { Add } from "@mui/icons-material";

export const BlogsManagePage = () => {
  const limit = 10;

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const currentPage =
    Number(searchParams.get("page")) > 0 ? searchParams.get("page") : 1;
  const fetchGetAllBlogs = () => {
    adminApi
      .getAllBlogs(currentPage, limit)
      .then((res) => {
        setTableData(res.data.data);
        setTotalItem(res.data.total);
        setTotalPage(res.data.total_pages);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setTableData([]);
    setTotalItem(0);
    setTotalPage(0);
    setLoading(true);
    fetchGetAllBlogs();
  }, [searchParams, currentPage]);

  return (
    <div className="flex flex-col p-10">
      <div className="flex justify-between items-center">
        <div className="text-3xl font-bold py-3 text-primary">
          Quản lý bài viết
        </div>
        <Button
          variant="contained"
          startIcon={<Add />}
          href="/admin/blog-create"
          size="small"
          className="hover:text-white"
          sx={{
            textDecoration: "none",
            textTransform: "none",
          }}
        >
          Soạn bài viết mới 
        </Button>
      </div>
      <Divider flexItem sx={{ my: 1 }} />

      <HashLoader
        loading={loading}
        color="#1890ff"
        size={50}
        className="self-center my-52"
      />

      {!loading && (
        <Box my="70px">
          <PublishedTable data={tableData} fetchGetAllBlogs={fetchGetAllBlogs}/>
        </Box>
      )}

      {!loading && (
        <div className="mt-4 flex justify-between items-center">
          <div className="mt-4 flex justify-between">
            <div>
              <p className="text-lg text-gray-500">
                Showing {(currentPage - 1) * limit + 1} -
                {currentPage * limit < totalItem
                  ? currentPage * limit
                  : totalItem}{" "}
                of {totalItem}
              </p>
            </div>
          </div>
          {totalPage > 1 && (
            <Pagination
              count={totalPage}
              page={currentPage}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) =>
                setSearchParams({
                  page: value,
                })
              }
              sx={{
                alignSelf: "end",
                my: 2,
                "& .MuiPaginationItem-root": {
                  "&.Mui-selected": {
                    backgroundColor: "primary.main",
                    color: "white",
                    borderColor: "primary.main",
                  },
                },
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

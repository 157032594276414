import { LoginPage } from "./pages/LoginPage";

export const authRoutes = () => [
  {
    path: "/login",
    name: "Login",
    exact: true,
    element: <LoginPage />,
  },
];

import footerBg from "../../assets/img/footer-bg.png";
// import logo_1 from "../../assets/img/logo-1.png";
// import logo_1 from "../../assets/img/logo-aivision.png";
import zalo from "../../assets/img/zalo-96.png";
import mess from "../../assets/img/messenger-64.png";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        paddingTop: "100px",
        backgroundImage: `url(${footerBg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        "@media (max-width: 500px)": {
          paddingTop: "0px",
        },
      }}
    >
      <Box
        sx={{
          paddingTop: "20px",
          paddingLeft: "100px",
          paddingRight: "100px",   
          paddingBottom: "20px",
          background: "rgba(214, 31, 35, 0.9)",
          color: "white",
          "@media (max-width: 500px)": {
            paddingTop: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingBottom: "20px",
          },
        }}
      >
        <Grid container sx={{ marginBottom: "20px", fontSize: "20px" }}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              "@media (max-width: 500px)": {
                marginBottom: "15px",
              },
            }}
          >
            <Typography sx={{ fontWeight: 600, marginBottom: "10px" }}>
              {t("about_company")}
            </Typography>
            <Typography sx={{ fontWeight: 300, textAlign: "justify" }}>
              {t("footer_about_company_description")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            container
            sx={{
              justifyContent: "center",
              "@media (max-width: 500px)": {
                justifyContent: "left",
              },
            }}
          >
            <Box>
              <Typography sx={{ fontWeight: 600, marginBottom: "10px" }}>
                {t("quick_link")}
              </Typography>
              <Grid
                item
                container
                display={"flex"}
                flexDirection={"row"}
                sx={{
                  "& ul": {
                    listStyleType: "none",
                    paddingLeft: 0,
                    marginRight: "25px",
                    fontSize: "1rem",
                  },
                }}
              >
                <ul>
                  <li><Link to="/about-us">{t("about_company")}</Link></li>
                  <li><Link to="/services">{t("our_services")}</Link></li>
                  {/* <li>{t("our_faq")}</li> */}
                </ul>
                <ul>
                  {/* <li>{t("meet_team")}</li>
                  <li>{t("news_media")}</li> */}
                  <li><Link to="/contact">{t("contact_us")}</Link></li>
                </ul>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={{ fontWeight: 600, marginBottom: "10px" }}>
              {t("newsletter")}
            </Typography>
            <Typography sx={{ fontWeight: 300, textAlign: "justify" }}>
              {t("newsletter_description")}
            </Typography>
          </Grid>
        </Grid>

        <Divider
          sx={{
            marginBottom: "20px",
            marginTop: "50px",
            backgroundColor: "white",
            "@media (max-width: 500px)": {
              marginTop: "0px",
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            "@media (max-width: 500px)": {
              justifyContent: "center",
            },
          }}
        >
          <Typography>@Copyright 2024, by Aivgroups.com</Typography>
          <Box
            sx={{
              display: "flex",
              "@media (max-width: 500px)": {
                display: "none",
              },
            }}
          >
            <Typography sx={{ marginLeft: "20px" }}>
              Setting & Privacy
            </Typography>
            <Typography sx={{ marginLeft: "20px" }}>FAQs</Typography>
            <Typography sx={{ marginLeft: "20px" }}>Contact</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

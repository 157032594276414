import { Box, Typography } from "@mui/material";

export function MyMessage(content, key) {
    return (
      <Box display="flex" key={key} py={1} height="min-content" justifyContent="end">
        <Box
          bgcolor="#3b85ff"
          padding={2}
          borderRadius="15px 15px 15px 15px"
          height="min-content"
        >
          <Typography color="white" fontSize="0.95rem">{content}</Typography>
        </Box>
      </Box>
    );
  }
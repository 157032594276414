export const servicesList = [
  {
    name: "AI Vision",
    index: 1,
  },
  {
    name: "Chatbot",
    index: 2,
  },
  {
    name: "Data Analytics",
    index: 3,
  },
  {
    name: "Smart HR Platform",
    index: 4,
  },
];
